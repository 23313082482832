<template>
  <div :class="['warp-history', { show: isShow }]">
    <b-sidebar
      id="sidebar-filter-history"
      :backdrop-variant="variant"
      backdrop
      shadow
      v-model="isShow"
      no-header
      right
    >
      <div class="sidepanel">
        <div
          class="sticky-top d-flex justify-content-between align-items-center px-3"
        >
          <span class="title-side">Chat History</span>
          <a href="javascripxt:void(0)" class="closebtn" @click="hide">×</a>
        </div>
        <div class="filter-form border-top pt-2 px-3">
          <div class="text-right mb-3">
            <b-button variant="secondary" class="w-auto" @click="importFile">
              Import LINE History Chat
            </b-button>
          </div>
          <b-row no-gutters>
            <b-col cols="6" class="pr-2">
              <b-form-group
                id="input-group-start"
                class="filter-date"
                label="From Date Time"
                label-for="input-start"
              >
                <datetime
                  input-id="start-date"
                  placeholder="วัน/เดือน/ปี"
                  v-model="filter.start"
                  format="dd/MM/yyyy HH:mm"
                  class="theme-pink"
                  input-class="form-control"
                  type="datetime"
                >
                </datetime>
              </b-form-group>
            </b-col>
            <b-col cols="6" class="pl-2">
              <b-form-group
                id="input-group-end"
                class="filter-date"
                label="End Date Time"
                label-for="input-end"
              >
                <datetime
                  input-id="end-date"
                  placeholder="วัน/เดือน/ปี"
                  v-model="filter.end"
                  format="dd/MM/yyyy HH:mm"
                  class="theme-pink"
                  input-class="form-control"
                  type="datetime"
                >
                </datetime>
              </b-form-group>
            </b-col>
          </b-row>
          <b-input-group class="mb-3">
            <b-form-input
              placeholder="Search Chat History"
              v-model="filter.search"
              class="position-relative"
            >
            </b-form-input>
            <span class="icon-search">
              <font-awesome-icon :icon="['fas', 'search']"
            /></span>
          </b-input-group>
          <b-button variant="secondary" class="w-100" @click="submitSearch">
            ค้นหา
          </b-button>
          <div class="chat-search-result py-3">
            <div
              v-for="(item, index) in chatList"
              :key="index + 'history'"
              class="msg-row"
            >
              <div class="d-flex justify-content-between">
                <p class="name">{{ item.senderName }}</p>
                <ChatDateTime class="time" :date="item.sendTime" />
              </div>
              <div class="message">
                {{ item.message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
    <ModalImportFile
      ref="modalImportFile"
      path="product/ImportBundleExcel"
      @success="importSuccess"
    />
  </div>
</template>

<script>
import ModalImportFile from "@/components/chat/ModalImportFile";
import ChatDateTime from "@/components/chat/ChatDateTime";
import { Datetime } from "vue-datetime";
import * as moment from "moment/moment";

export default {
  props: {
    chatRoomId: {
      required: false,
      type: String
    }
  },
  components: {
    datetime: Datetime,
    ModalImportFile,
    ChatDateTime
  },
  data() {
    return {
      variant: "dark",
      isShow: false,
      filter: {
        start: moment()
          .subtract(7, "days")
          .format(),
        end: moment().format(),
        search: "",
        length: 20,
        chatroomId: this.chatRoomId,
        messageId: ""
      },
      chatList: []
    };
  },
  methods: {
    hide() {
      this.isShow = false;
    },
    show() {
      this.isShow = true;
      this.submitSearch();
    },
    importFile() {
      this.$refs.modalImportFile.show();
    },
    submitSearch() {
      let body = { ...this.filter };
      fetch(`${this.$chatBaseUrl}/message/GetChatHistory`, {
        method: "post",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            this.chatList = this.chatList.concat(result.detail);
            if (this.chatList.length > 0) {
              this.filter.messageId =
                result.detail[result.detail.length - 1].messageId;
            }
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep #sidebar-filter-history {
  background-color: rgba(70, 70, 70, 0.5) !important;
}
::v-deep .btn-secondary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.warp-history {
  position: fixed;
  top: 0;
  right: 0;
  height: 100dvh;
  width: 0;
  z-index: 1040;
  overflow-x: hidden;
  width: 0%;
}
.warp-history.show {
  width: 100%;
  //   background-color: white;
  .sidepanel {
    width: 400px;
    background-color: rgb(255, 255, 255);
    height: 100dvh;
    color: #707070;
    border-left: 1px solid #b3afaf6e;
    margin-left: auto;
    transition: 0.5s;
    a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      color: #707070;
      display: block;
      transition: 0.3s;
    }
    .closebtn {
      font-size: 23px;
    }
    .title-side {
      font-size: 16px;
    }
    .filter-form {
      min-height: calc(100dvh - 60px);
    }
    .filter-date {
      margin-bottom: 15px;
      label {
        color: #575757;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
    .icon-search {
      position: absolute;
      top: 7px;
      right: 15px;
      color: #768192;
    }
    .chat-search-result {
      .msg-row {
        margin-bottom: 10px;
        .name {
          margin-bottom: 0;
          font-size: 14px;
          color: #000000;
          font-weight: 600;
        }
        .time {
          color: #bfbfbf;
          font-size: 14px;
        }
      }
    }
  }
}
</style>

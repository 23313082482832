<template>
  <div class="panel-ChatOrderPanel">
    <div v-if="displayCheckboxSelectUserChat" class="disabled-panel"></div>
    <div class="h-100" v-if="tabPage === 0">
      <ChatOrderProfileCard
        ref="chatOrderProfileCard"
        :userInfo="orderSelected.buyerInfo.userInfo"
        :userCustomField="userCustomField"
        :userChatInfo="userChatInfo"
        :userDetail="userDetail"
        :userTier="userTier"
        :crmList="crmList"
        :crmDetail="crmDetail"
        :select="select"
        :isShowDetail="isShowDetail"
        :isShowBoth="isShowBoth"
        @handle-show-modal-user="$refs.ChatOrderProfileModalDetail.show()"
        @get-detail="getCrmConnectList"
        @get-chatlist="$emit('get-chatlist')"
        @onchange-crm="getUserDetail"
        @get-address="getAddress"
        @handle-show-modal-custom-field="
          item => $refs.ChatOrderProfileCustomFieldModalDetail.show(item)
        "
        @handle-show-modal-tier="
          item => $refs.ChatOrderProfileTierModal.show(item)
        "
        class="pt-3"
      />
      <!-- @handle-chage-page="(val) => (tabPage = val)" -->
      <div>
        <hr class="text-muted my-0 mt-2" />
      </div>
      <div class="w=100" @touchstart.stop @touchmove.stop>
        <b-tabs
          v-if="isShowOrder"
          v-model="tabIndex"
          content-class="px-3"
          class="tab-title"
          fill
        >
          <b-tab>
            <template #title>
              <!-- <font-awesome-icon :icon="['fas', 'cart-plus']" class="mr-2" /> -->
              <strong>Profile</strong>
            </template>
          </b-tab>
          <b-tab>
            <template #title>
              <!-- <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-2" /> -->
              <strong>Address</strong>
            </template>
          </b-tab>
          <b-tab>
            <template #title>
              <strong>Variable</strong>
            </template>
          </b-tab>
        </b-tabs>
      </div>

      <ChatOrderListPanel
        ref="chatOrderListPanel"
        v-if="userChatInfo.externalId && tabIndex == 0 && !loadingDetail"
        :userDetail="userDetail"
        :userId="userChatInfo.externalId"
        :userInfo="orderSelected.buyerInfo.userInfo"
        :orderSelected="orderSelected"
        @set-buyer-info-select="val => (orderSelected.buyerInfo = val)"
        :handleFinishCreateOrder="handleFinishCreateOrder"
        :handleShowModalCopy="handleShowModalCopy"
        :onsetOrderSelected="onsetOrderSelected"
        @set-address-from-list="handleGetAddressFromList"
        :chatRoomId="chatRoomId"
      />
      <ChatOrderHistoryPanel
        ref="chatOrderHistoryPanel"
        v-if="userChatInfo.externalId && tabIndex == 1"
        :userId="userChatInfo.externalId"
        :userDetail="userDetail"
        :addressList="addressList"
        :isLoadingAddress="isLoadingAddress"
        :isLoadingAddressList="isLoadingAddressList"
        :userInfo="orderSelected.buyerInfo.userInfo"
        :orderSelected="orderSelected"
        :onsetOrderSelected="onsetOrderSelected"
        :handleShowModalCopy="handleShowModalCopy"
        @set-value-order-selected="val => (orderSelected = val)"
      />
      <ChatOrderVariable
        v-if="userChatInfo.externalId && tabIndex == 2"
        :userId="userChatInfo.externalId"
      />
    </div>
    <!-- <ChatOrderProfilePanel
      v-if="tabPage === 1"
      :userInfo="userInfo"
      :userDetail="userDetail"
      :userChatInfo="userChatInfo"
      @handle-chage-page="(val) => (tabPage = val)"
      @handle-get-user-info="getUserInfo"
    /> -->
    <ChatOrderProfileModalDetail
      v-if="isShowOrder"
      ref="ChatOrderProfileModalDetail"
      :orderSelected="orderSelected"
      :userGUID="userChatInfo.externalId"
      :getUserInfo="getUserInfo"
    />
    <ChatOrderProfileCustomFieldModalDetail
      v-if="isShowOrder"
      ref="ChatOrderProfileCustomFieldModalDetail"
      :userGUID="userChatInfo.externalId"
      :getCustomFieldList="getCustomFieldList"
    />
    <ChatOrderProfileTierModal
      v-if="isShowOrder"
      ref="ChatOrderProfileTierModal"
      :userGUID="userChatInfo.externalId"
      :getCustomerTierByGUID="getCustomerTierByGUID"
    />
    <!-- :userInfo="orderSelected.buyerInfo.userInfo" -->
    <!-- v-bind:isShow="isShowModalAddress"
      v-on:setShowModalAddress="setShowModalAddress"
      v-bind:orderSelected="orderSelected"
      v-bind:orderStatus="orderSelected.statusId"
      v-bind:v="$v"
      :onUpdatedAddress="onUpdatedAddress" -->
    <!-- v-if="forceRefresh" -->
    <ModalCopyLink
      ref="modalCopyLink"
      :urlLink="urlLink"
      :remarkMessage="remarkMessage"
    />
  </div>
</template>

<script>
import ChatOrderProfileCard from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfileCard";
// import ChatOrderProfilePanel from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfilePanel";
import ChatOrderHistoryPanel from "@/components/chat/chatOrder/chatOrderHistory/ChatOrderHistoryPanel";
import ChatOrderVariable from "@/components/chat/chatOrder/chatOrderList/ChatOrderVariable";
import ChatOrderProfileModalDetail from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfileModalDetail";
import ChatOrderListPanel from "@/components/chat/chatOrder/chatOrderList/ChatOrderListPanel";
import ModalCopyLink from "@/components/modal/ModalCopyLink";
import ChatOrderProfileCustomFieldModalDetail from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfileCustomFieldModalDetail";
import ChatOrderProfileTierModal from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfileTierModal";
import { mapGetters } from "vuex";

export default {
  name: "ChatOrderPanel",
  components: {
    ChatOrderProfileCard,
    // ChatOrderProfilePanel,
    ChatOrderHistoryPanel,
    ChatOrderVariable,
    ChatOrderProfileModalDetail,
    ChatOrderListPanel,
    ModalCopyLink,
    ChatOrderProfileCustomFieldModalDetail,
    ChatOrderProfileTierModal
  },
  props: {
    userChatInfo: {
      required: false,
      type: Object
    },
    socialType: {
      required: false,
      type: Number
    },
    chatRoomId: {
      required: true,
      type: String
    },
    isShowBoth: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      crmList: [],
      crmDetail: {},
      addressList: [],
      isShowDetail: true,
      isLoadingAddress: false,
      isLoadingAddressList: true,
      select: "",
      tabPage: 0,
      tabIndex: 0,
      loadingDetail: true,
      orderSelected: {
        statusId: 0,
        buyerInfo: {
          userInfo: {},
          shippingAddress: {},
          billingAddress: {},
          sameAddress: true,
          isRequestTax: false,
          isShowOrder: true
        }
      },
      userCustomField: [],
      userTier: {
        id: 0,
        name: "",
        brandId: this.$route.query.brandID
      },
      // userInfo: {},
      userDetail: {},
      defaultValueAddress: {
        id: 0,
        name: "",
        userGUID: "",
        defaultBilling: null,
        defaultShipping: null,
        firstname: null,
        lastname: null,
        address: null,
        road: null,
        district: null,
        subdistrict: null,
        province: null,
        zipcode: null,
        taxNo: null,
        zipcodeId: 0,
        nameTitleId: 0
      },
      // forceRefresh: true,
      // isShowModalAddress: false,
      remarkMessage: "",
      urlLink: ""
    };
  },
  computed: {
    ...mapGetters({
      // chatModeId: "getChatMode",
      // channelId: "getChatChannel",
      // channelList: "getChatChannelList",
      displayCheckboxSelectUserChat: "getDisplayCheckboxSelectUserChat"
    })
  },
  methods: {
    onsetOrderSelected() {
      this.orderSelected = {
        branchId: null,
        brandId: this.$route.query.brandID,
        canDownloadJobSheet: false,
        canUpdateTracking: false,
        checkSum: "",
        countFreeProduct: 0,
        discount: 0,
        discountPromotion: 0,
        expiredDate: "",
        freeProducts: [],
        grandTotal: 0,
        id: 0,
        invoiceNo: "",
        isFreeProduct: false,
        note: "",
        paymentMethodId: 0,
        paymentName: "-",
        paymentReference: null,
        productDiscount: [],
        products: [],
        promotions: [],
        promoCode: "",
        promoCodeMessage: "",
        reasonId: 0,
        reasonNote: "",
        replyReferenceId: null,
        replyTypeId: 0,
        saleChannel: 0, //this.userInfo.socialSalesChannelId,
        shippingCost: 0,
        shippingTypeId: 0,
        shippingTypeName: "",
        socialRef: {},
        statusId: 1,
        statusName: "สั่งซื้อ",
        total: 0,
        totalQuantity: 0,
        trackingNo: {},
        userGUID: null,
        weightTotal: 0,
        buyerInfo: {
          userInfo: {
            telephone: null,
            firstname: null,
            lastname: null,
            email: null,
            socialMediaId: 0,
            socialMediaName: null,
            socialReferenceId: null,
            socialSourceId: this.socialType
          },
          shippingAddress: {
            id: 0,
            firstname: null,
            lastname: null,
            address: null,
            road: null,
            province: null,
            district: null,
            subdistrict: null,
            zipcode: null,
            shippingNote: ""
          },
          billingAddress: {
            id: 0,
            firstname: null,
            lastname: null,
            address: null,
            road: null,
            province: null,
            district: null,
            subdistrict: null,
            zipcode: null,
            shippingNote: ""
          },
          sameAddress: true,
          isConsent: false,
          isRequestTax: false
        }
      };
      // this.getUserInfo();
      this.getCustomFieldList();
      this.getCustomerTierByGUID();
      this.$store.dispatch("setIsLoading", false);
    },
    async getNotes() {
      await this.$store.dispatch("getAgentNoteType", {
        chatroomId: this.chatRoomId,
        type: 1
      });
      await this.$store.dispatch("getAgentNoteType", {
        chatroomId: this.chatRoomId,
        type: 2
      });
    },
    getCustomFieldList: function() {
      let filter = {
        pageNo: 1,
        perPage: -1,
        userGUID: this.userChatInfo.externalId,
        search: ""
      };
      this.$axios
        .post(`${this.$chatBaseUrl}/Customer/CustomField/list`, filter)
        .then(response => response.data)
        .then(res => {
          if (res.result == 1) {
            this.userCustomField = res.detail.data;
          }
        });
    },
    getCustomerTierByGUID: function() {
      this.$axios
        .get(
          `${this.$chatBaseUrl}/customer/tier/${this.userChatInfo.externalId}`
        )
        .then(response => response.data)
        .then(res => {
          if (res.result == 1) {
            this.userTier = res.detail;
          }
        });
    },
    getUserInfo: function() {
      this.$axios
        .get(
          `${this.$baseUrlOrder}/api/user/guid/${this.userChatInfo.externalId}`
        )
        .then(response => response.data)
        .then(res => {
          if (res.result) {
            this.orderSelected.buyerInfo.userInfo = res.detail;
            this.orderSelected.saleChannel = res.detail.socialSalesChannelId;
            this.orderSelected.buyerInfo.sameAddress = res.detail.sameAddress;
            this.orderSelected.buyerInfo.isRequestTax =
              res.detail.isRequestTaxInvoice;
            if (this.orderSelected.id === 0)
              this.orderSelected.buyerInfo.userInfo.socialSourceId = this.socialType;

            // this.$axios
            //   .get(
            //     `${this.$baseUrl}/api/user/${this.userChatInfo.externalId}/address/list`
            //   )
            //   .then((respo) => respo.data)
            //   .then((result) => {
            //     if (result.result) {
            //       if (result.detail.length == 0) {
            //         this.orderSelected.buyerInfo.shippingAddress = this.defaultValueAddress;
            //         this.orderSelected.buyerInfo.billingAddress = this.defaultValueAddress;
            //       } else {
            //         this.orderSelected.buyerInfo.shippingAddress =
            //           result.detail[0];
            //         if (result.detail.length == 1) {
            //           this.orderSelected.buyerInfo.billingAddress =
            //             result.detail[0];
            //         }
            //         if (result.detail.length > 1) {
            //           this.orderSelected.buyerInfo.billingAddress =
            //             result.detail[1];
            //         }
            //       }
            //     }
            //   })
            //   .catch((error) => {
            //     console.log("error", error);
            //   });
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getUserDetail: async function(data) {
      // get note 2 types
      this.$refs.chatOrderProfileCard
        ? (this.$refs.chatOrderProfileCard.isLoading = true)
        : "";
      await this.$axios
        .get(
          `${this.$chatBaseUrl}/crm/customer/${this.userChatInfo.externalId}/${data.user_guid}/${this.chatRoomId}/${this.$route.query.brandID}`
        )

        .then(response => response.data)
        .then(res => {
          if (res.result) {
            this.userDetail = res.detail;
            if (this.$refs.chatOrderListPanel) {
              this.$refs.chatOrderListPanel.$refs.ticket.getList();
              this.$refs.chatOrderListPanel.$refs.noteDetail.getList();
            }
          }
        })
        .catch(error => {
          this.loadingDetail = false;
          console.log("error", error);
        });
    },
    getCrmConnectList: async function(item) {
      await this.$axios
        .get(
          `${this.$chatBaseUrl}/crm/connect-list/${this.userChatInfo.externalId}`
        )
        .then(response => response.data)
        .then(async res => {
          if (res.result) {
            this.crmList = res.detail;
            if (this.crmList.length > 0) {
              let index = null;
              if (item) {
                index = this.crmList.findIndex(
                  t => t.user_guid == item.user_guid
                );
              } else index = 0;

              this.select = this.crmList[index].first_name_th;
              this.crmDetail = this.crmList[index];
              await this.getUserDetail(this.crmDetail);
              await this.getAddress(this.crmDetail);
            } else {
              this.select = "Not Connect";
            }
            this.isShowDetail = false;
          }
        })
        .catch(error => {
          this.loadingDetail = false;
          console.log("error", error);
        });
    },
    getAddress: async function(item) {
      this.isLoadingAddress = true;
      await this.$axios
        .get(
          `${this.$chatBaseUrl}/crm/address/${this.userChatInfo.externalId}/${item.user_guid}/${this.$route.query.brandID}`
        )
        .then(response => response.data)
        .then(res => {
          if (res.result) {
            this.addressList = res.detail;
            this.select = item.first_name_th;
          }
          this.isLoadingAddress = false;
        })
        .catch(error => {
          this.isLoadingAddress = false;
          this.loadingDetail = false;
          console.log("error", error);
        });
    },
    handleFinishCreateOrder() {
      this.tabIndex = 1;
      // this.getUserInfo();
      this.onsetOrderSelected();
    },
    handleShowModalCopy(resData) {
      this.remarkMessage = resData.message;
      this.urlLink = resData.urlLink;
      // setTimeout(() => {
      this.$refs.modalCopyLink.show();
      // }, 500);
    },
    handleGetAddressFromList() {
      const tempAddressList = this.$store.getters.getChatUserAddressList;
      if (tempAddressList.length == 0) {
        this.orderSelected.buyerInfo.shippingAddress = this.defaultValueAddress;
        this.orderSelected.buyerInfo.billingAddress = this.defaultValueAddress;
      } else {
        this.orderSelected.buyerInfo.shippingAddress = tempAddressList[0];
        if (tempAddressList.length == 1) {
          this.orderSelected.buyerInfo.billingAddress = tempAddressList[0];
        }
        if (tempAddressList.length > 1) {
          this.orderSelected.buyerInfo.billingAddress = tempAddressList[1];
        }
      }
    }
    // setShowModalAddress(value) {
    //   // if (this.orderSelected.statusId !== 0) {
    //   console.log(this.isShowModalAddress);
    //   this.isShowModalAddress = value;
    //   console.log(this.isShowModalAddress);
    //   //   if (!value) {
    //   //     this.getOrderInformationById(this.orderSelected.id);
    //   //   }
    //   //   this.handleForcrrefresh();
    //   // }
    // },
    // onUpdatedAddress() {
    //   // this.handleAddress();
    //   // this.$v.orderSelected.buyerInfo.$touch();
    //   // if (this.$v.orderSelected.buyerInfo.$error) {
    //   //   return;
    //   // }
    //   // this.sendUpdatedAddress(this.orderSelected.buyerInfo);
    // },
    // handleForcrrefresh() {
    //   this.forceRefresh = false;
    //   this.$nextTick(() => {
    //     this.forceRefresh = true;
    //   });
    // },
  },
  created: async function() {
    // this.$store.commit("setShowProfileTab", false);
    this.loadingDetail = true;
    this.isShowOrder = this.$store.getters.getIsShowProfileTab;
    this.onsetOrderSelected();
    // await this.getUserDetail();
    await this.getCrmConnectList();
    // this.getUserInfo();
    this.getNotes();
    this.$store.commit(
      "UPDATE_CHAT_USER_GUID_SELECTED",
      `${this.userChatInfo.externalId}`
    );
    this.loadingDetail = false;
    this.isLoadingAddressList = false;
    // this.$store.dispatch("handleGetChatAddressList");
  }
  // validations() {
  //   return {
  //     orderSelected: {
  //       buyerInfo: {
  //         userInfo: {
  //           firstname: { required, alpha },
  //           lastname: { required, alpha },
  //           email: { email },
  //           telephone: { required, minLength: minLength(9) },
  //         },
  //         shippingAddress: {
  //           firstname: { required, alpha },
  //           lastname: { required, alpha },
  //           telephone: { required, minLength: minLength(9) },
  //           address: { required },
  //           district: {
  //             required,
  //           },
  //           subdistrict: {
  //             required,
  //           },
  //           province: {
  //             required,
  //           },
  //           zipcode: {
  //             required,
  //             numeric,
  //             minValue: minValue(5),
  //           },
  //         },

  //         billingAddress: {
  //           nameTitleId: {
  //             required: requiredIf(function() {
  //               return (
  //                 !this.orderSelected.buyerInfo.sameAddress ||
  //                 this.orderSelected.buyerInfo.isRequestTax
  //               );
  //             }),
  //             valueDefault(value) {
  //               return valueDefault(
  //                 value,
  //                 !this.orderSelected.buyerInfo.sameAddress ||
  //                   this.orderSelected.buyerInfo.isRequestTax
  //               );
  //             },
  //           },

  //           firstname: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //             alpha,
  //           },
  //           lastname: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //             alpha,
  //           },

  //           address: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //           },
  //           district: {
  //             required: requiredIf(function() {
  //               return !this.orderSelected.buyerInfo.sameAddress;
  //             }),
  //           },
  //           subdistrict: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //           },
  //           province: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //           },
  //           zipcode: {
  //             required: requiredIf(function() {
  //               return this.orderSelected.buyerInfo.sameAddress !== true;
  //             }),
  //             numeric,
  //             minValue: minValue(5),
  //           },
  //           tax: {
  //             required: requiredIf(function() {
  //               return (
  //                 this.orderSelected.buyerInfo.sameAddress !== true ||
  //                 this.orderSelected.buyerInfo.isRequestTax ==
  //                   true
  //               );
  //             }),
  //             numeric,
  //             minValue: minValue(13),
  //           },
  //         },
  //       },
  //     },
  //   };
  // },
};
</script>

<style lang="scss" scoped>
.panel-ChatOrderPanel {
  height: 100vh;
  position: relative;
}
.disabled-panel {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7) !important;
  height: 100%;
  width: 100%;
  z-index: 2;
}
::v-deep .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  color: #707070 !important;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom: 4px solid #fff !important;
  border-top: 0px solid #fff !important;
  border-left: 0px solid #fff !important;
  border-right: 0px solid #fff !important;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 0px solid #832abf !important;
  color: #832abf !important;
  background-color: #ffffff !important;
  border-bottom: 4px solid #832abf !important;
}
::v-deep .nav-link:hover {
  border: 0px solid #a15ccf !important;
  border-bottom: 4px solid #9450c2 !important;
}
hr {
  border-color: #00001512;
}
@media (max-width: 767.98px) {
  .panel-ChatOrderPanel {
    position: relative;
    top: 41px;
    height: calc(100vh - 41px) !important;
  }
  ::v-deep .tabs.tab-title {
    ul.nav-tabs {
      overflow-y: auto;
      width: 100%;
      flex-wrap: nowrap;
    }
  }
  .section {
    height: calc(100dvh - 157px);
  }
}
</style>
